<template>
  <van-row class="buytrain">
    <van-image class="pic_margin" width="100vw" src="/banner_train.jpg" />
    <van-row style="padding-top:1rem" class="pic-next-cont itembk">
      <!-- 始末车站选择cell -->
      <van-cell>
        <template slot="title">
          <van-notice-bar class="tips" v-if="tips" left-icon="info-o">申请单所选城市没有火车站，请调整行程。</van-notice-bar>
          <div class="gap" v-if="tips"></div>
          <div class="gap" v-if="tips"></div>
          <van-row type="flex" justify="space-between" align="bottom" style="height: 20px;">
            <van-col>
              <van-row type="flex" justify="center">
                <span style="font-size: 10px;color: #adadad">出发城市</span>
              </van-row>
            </van-col>
            <van-col>
              <van-row type="flex" justify="center">
                <div style="font-size: 10px;color: #adadad"></div>
              </van-row>
            </van-col>
            <van-col>
              <van-row type="flex" justify="center">
                <span style="font-size: 10px;color: #adadad">到达城市</span>
              </van-row>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between" align="center">
            <van-col span="8">
              <van-row
                type="flex"
                justify="start"
                @click="chooseTrainStation('train/setOrderFrom')"
              >
                <h4 class="animate__animated animate__delay-08" ref="left">
                  <span
                    :class="orderInfo.trainFromStation=='请选择'?'red':''"
                  >{{orderInfo.trainFromStation}}</span>
                </h4>
              </van-row>
            </van-col>
            <van-col span="8">
              <van-row @click="changeCity" type="flex" justify="center">
                <van-icon
                  class="iconfont animate__animated"
                  class-prefix="icon"
                  name="wangfandijia"
                  ref="change"
                />
              </van-row>
            </van-col>
            <van-col span="8">
              <van-row type="flex" justify="end" @click="chooseTrainStation('train/setOrderTo')">
                <h4 class="animate__animated animate__delay-08" ref="right">
                  <span
                    :class="orderInfo.trainToStation=='请选择'?'red':''"
                  >{{orderInfo.trainToStation}}</span>
                </h4>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-cell>
      <!-- 始末车站选择cell -->
      <!-- 出发时间选择cell -->
      <van-cell>
        <template slot="title">
          <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
            <van-col>
              <van-row type="flex" justify="center">
                <span style="font-size: 10px;color: #adadad">出发日期</span>
              </van-row>
            </van-col>
          </van-row>
          <van-row type="flex" justify="start" align="center" style="height: 40px">
            <van-col>
              <van-row type="flex" justify="start" align="bottom" @click="show_calendar = true">
                <van-col>
                  <span class="h4">{{tempTime[0]| comverTime('YYYY-MM-DD')}}</span>
                </van-col>
                <van-col>
                  <span>{{tempTime[0]| WhichDay}}</span>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-cell>
      <!-- 出发时间选择cell -->
      <!-- 车型选择cell -->
      <van-cell>
        <template slot="title">
          <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
            <van-col>
              <van-row type="flex" justify="center">
                <span style="font-size: 10px;color: #adadad">车次类型</span>
              </van-row>
            </van-col>
          </van-row>
          <van-row type="flex" justify="start" align="center" style="height: 40px">
            <van-col>
              <van-row type="flex" justify="start" align="bottom" @click="changeTrainType">
                <van-col>
                  <span class="h4">{{orderInfo.trainType}}</span>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-cell>
      <!-- 车型选择cell -->
      <!-- 搜索按钮cell -->
      <van-cell>
        <template slot="title">
          <van-row type="flex" justify="center" align="center" style="height: 60px">
            <van-button
              style="border-radius: 8px;font-size: 16px"
              block
              @click="searchTrain"
              color="linear-gradient(to right, #4bb0ff, #2c50d4)"
            >搜 索</van-button>
          </van-row>
        </template>
      </van-cell>
      <!-- 搜索按钮cell -->
    </van-row>

    <History ref="history" :historyKey="'trainHistory'" @setCity="setCity" />

    <!--日期选择-->
    <van-calendar
      v-model="show_calendar"
      color="#198BDA"
      confirm-text="确定"
      :show-confirm="false"
      confirm-disabled-text="请选择出发时间"
      :default-date="new Date(tempTime[0])"
      :max-date="maxDate"
      @confirm="calendarConfirm"
    />

    <!--火车类型选择-->
    <van-action-sheet
      v-model="show_train_type"
      :close-on-click-action="true"
      :actions="train_type_actions"
      @select="trainTypeOnSelect"
    />

    <LoadingCircle :show="loading" />
  </van-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import logic from "@/services/commonLogic";
import LoadingCircle from "../../components/LoadingCircle";
import History from "@/components/History";
import { WhichDay, AddDate, DepartureDate } from "@/services/date.js";
import { checkCity } from "@/services/train";
export default {
  name: "BuyTrain",
  computed: {
    ...mapGetters(["personList", "travelInfo", "orderInfo", "tempTime"]),
    maxDate: function () {
      return new Date(AddDate(new Date(), 29));
    },
    // 是否为返程，终到站等于行程出发地
    isBack: function () {
      return this.orderInfo.trainToStation == this.travelInfo.travelFrom;
    },
    // 是否提示？（匹配不到行程单的车站时候需要提示）
    tips: function () {
      return this.orderInfo.trainToStation == "请选择" || this.orderInfo.trainFromStation == "请选择";
    },
  },
  components: { LoadingCircle, History },
  activated() {
    window.setTitle("选择城市");
  },
  mounted() {
    // 初始化订单信息
    if (this.orderInfo.date == "") {
      let obj = {
        date: DepartureDate(this.travelInfo.startDate), // 出发日期
        trainFromStation: this.travelInfo.travelFrom, // 出发站
        trainToStation: this.travelInfo.travelTo, // 终点站
        trainType: "不限车型", //车次类型
      };
      // 筛选异常车站。
      obj.trainToStation = checkCity(obj.trainToStation).name || "请选择";
      obj.trainFromStation = checkCity(obj.trainFromStation).name || "请选择";
      this.$store.dispatch("train/setOrderInfo", obj);
    }
    // if (this.tempTime == "") {
      this.$store.dispatch("train/SETTEMPTIME", [this.travelInfo.startDate, this.travelInfo.endDate]);
    // }
  },
  data() {
    return {
      loading: false,
      canChange: true, // 是否可以点击交换城市
      show_calendar: false, // 展示日历
      calendar_formatter: logic.calendarFormat, // 格式化日历
      isLoading: false, // 展示加载中
      show_train_type: false, // 车次类型选择开关
      train_type_actions: [
        { name: "不限车型" },
        { name: "高铁 / 动车" },
        { name: "普通" },
      ],
    };
  },
  methods: {
    // 交换城市动画，1秒禁止点击，过度效果参考animate.css 重写了部分动画效果在全局样式中查看
    changeCity() {
      if (this.canChange) {
        this.canChange = false;
        // 解构置换数据
        [this.orderInfo.trainFromStation, this.orderInfo.trainToStation] = [
          this.orderInfo.trainToStation,
          this.orderInfo.trainFromStation,
        ];
        this.tempTime.sort();
        if (this.isBack) this.tempTime.reverse();
        // 动画由css实现
        this.$refs.left.classList.add("animate__doubleSlideInRight");
        this.$refs.right.classList.add("animate__doubleSlideInLeft");
        this.$refs.change.classList.add("animate__flip");
        setTimeout(() => {
          this.canChange = true;
          this.$refs.left.classList.remove("animate__doubleSlideInRight");
          this.$refs.right.classList.remove("animate__doubleSlideInLeft");
          this.$refs.change.classList.remove("animate__flip");
        }, 1000);
        // 更新vuex
        this.$store.dispatch("train/setOrderInfo", this.orderInfo);
      }
    },

    // 选择城市
    chooseTrainStation(vuxFunction) {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({
          path: "/selectCity",
          query: logic.queryPathEncrypt({ function: vuxFunction }),
        });
      }, 200);
    },
    // 日历 选择日期
    calendarConfirm(date) {
      this.tempTime[0] = moment(date).format("YYYY-MM-DD");
      this.$store.dispatch("train/SETTEMPTIME", this.tempTime);
      this.show_calendar = false;
    },
    // 保存车次类型
    trainTypeOnSelect(item) {
      this.orderInfo.trainType = item.name;
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
    },
    // 打开修改车次类型
    changeTrainType() {
      this.show_train_type = true;
    },
    // 搜索按钮
    searchTrain() {
      if (
        !this.orderInfo.trainToStation ||
        this.orderInfo.trainToStation == "请选择" ||
        !this.orderInfo.trainFromStation ||
        this.orderInfo.trainFromStation == "请选择"
      ) {
        return this.$toast.fail({
          duration: 1000,
          message: "请选择城市",
        });
      }
      this.orderInfo.date = this.tempTime[0];
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
      this.$refs.history.append({
        to: this.orderInfo.trainToStation,
        from: this.orderInfo.trainFromStation,
      });
      if (this.orderInfo.trainFromStation == this.orderInfo.trainToStation)
        return this.$toast.fail("始末站相同不妨尝试下用车");
      this.$router.push({
        path: this.$route.name.includes("Change")
          ? "searchTrainChange"
          : "/searchTrain",
      });
    },

    // 历史记录选择城市
    setCity(data) {
      this.orderInfo.trainToStation = data.to;
      this.orderInfo.trainFromStation = data.from;
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
    },
  },
  filters: {
    WhichDay: WhichDay, // 过滤器。 今日，明日等
  },
};
</script>

<style lang="less" src="@/styles/buyCommon.less" scoped></style>
<style scoped>
</style>