<template>
  <!-- 搜索选择城市页面下方有个历史记录 -->
  <div class="history">
    <div v-show="list.length>0">
      <van-icon name="search" style="top: 2px" />
      <span
        class="list-text"
        v-for="(item,i) in list"
        :key="i"
        @click="$emit('setCity',item)"
      >{{item.from}}-{{item.to}}</span>
      <span class="list-text blue" @click="reset">清除历史</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "History",
  props: {
    historyKey: {
      default: () => "trainHistory"
    }
  },
  computed: {},
  data() {
    return {
      list: []
    };
  },
  methods: {
    // 清空历史记录
    reset() {
      localStorage.setItem(this.historyKey, JSON.stringify([]));
      this.list = [];
    },
    // 追加
    append(data) {
      let f = true;
      this.list.forEach(element => {
        f = f && !(element.to == data.to && element.from == data.from);
      });
      if (f) {
        this.list.push({
          to: data.to,
          from: data.from
        });
        localStorage.setItem(this.historyKey, JSON.stringify(this.list));
      }
    }
  },
  created() {
    if (!localStorage.getItem(this.historyKey)) this.reset();
    this.list = JSON.parse(localStorage.getItem(this.historyKey)) || [];
  }
};
</script>

<style lang="less" scoped>
.history {
  overflow: hidden;
  div {
    width: 95vw;
    margin: 0 auto;
    white-space: nowrap;
    overflow: scroll;
    .list-text {
      padding: 0.4rem 0.4rem 0.8rem;
      display: inline-block;
    }
  }
}
</style>